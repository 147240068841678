import React from "react";
import ReactDOM from "react-dom/client";
import PropTypes from "prop-types";
import reactToWebComponent from "react-to-webcomponent";
import App from "./app.js";

// PropTypes validation for the props passed to the App component
App.propTypes = {
  bot_id: PropTypes.string.isRequired, // ID of the bot, must be a string and required
  userid: PropTypes.string.isRequired, // ID of the user, must be a string and required
  user_image: PropTypes.string.isRequired, // Image of the user, must be a string and required
  template: PropTypes.string.isRequired, // Template for the app, must be a string and required
  app_host: PropTypes.string.isRequired // Host of the app, must be a string and required
};

// Defining a custom web component "react-counter" using reactToWebComponent function
customElements.define(
  "react-counter", // Name of the custom web component
  reactToWebComponent(App, React, ReactDOM) // Converting React App component to web component
);
