import React, { useState, useEffect } from "react";
import styles from "./resendButton.module.css";
import Axios from "../../../utils/axios";

const ResendButton = ({ apiBaseUrl, sender, template }) => {
    const [buttonText, setButtonText] = useState("Resend email");
    const [isDisabled, setIsDisabled] = useState(false);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        let countdown;
        if (timer > 0) {
            countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsDisabled(false);
            setButtonText("Resend email");
        }

        return () => clearInterval(countdown);
    }, [timer]);

    const handleResend = async () => {
        setIsDisabled(true);
        setButtonText("Resending...");
        setTimer(30);

        try {
            const response = await Axios.post(`${apiBaseUrl}/chatBot/resend-verification-email`, { sender });
            if (response.data.status === 200) {
                setButtonText("Sent");
            } else {
                setButtonText("Failed");
            }
        } catch (error) {
            setButtonText("Failed");
        }
    };

    return (
        <button onClick={handleResend} disabled={isDisabled}
            className={`${styles.linkButton}  ${styles["link_" + template]} ${isDisabled ? styles.disabled : ""}`}
        >
            {isDisabled && timer > 0 ? `Resend email in ${timer} sec` : buttonText}
        </button>
    );
};

export default ResendButton;
