import React, { useEffect, useState } from "react";
import Axios from "./utils/axios";
import { getMainApiHost } from "./constants/constants";
import useSocket from "./components/hooks/useSocket";
import useChatMessages from "./components/Chatbot/hooks/useChatMessages";
import styles from "./index.module.css";

// Chatbot templates
import ChatBotIndex from "./components/Chatbot/index";
import ChatBotDenhanIndex from "./components/ChatbotDenhan/index";
import ChatBotBtcIndex from "./components/ChatbotBtc/index";
import ChatBotDanielsIndex from "./components/ChatbotDaniels/index";
import ChatBotMagicBrickIndex from "./components/ChatbotMagicBrick/index";
import ChatBotLundiniomIndex from "./components/ChatbotLundiniom/index";
import ChatBotTalbiesIndex from "./components/ChatbotTalbies";
import ChatBotCharlessinclairIndex from "./components/ChatbotCharlessinclair";

const chatbotComponents = {
  denhan: ChatBotDenhanIndex,
  btc: ChatBotBtcIndex,
  daniels: ChatBotDanielsIndex,
  magicbrick: ChatBotMagicBrickIndex,
  lundiniom: ChatBotLundiniomIndex,
  talbies: ChatBotTalbiesIndex,
  charlessinclair: ChatBotCharlessinclairIndex,
};

const App = ({ bot_id, userid, user_image, template, app_host }) => {
  const [apiHost, apiBaseHost] = getMainApiHost(app_host);
  const { connectSocket, newSocketMessage, isChatClose, setChatClose, setSocketNewMessage } = useSocket(apiHost);
  const [isIconsetVisible, setIconsetVisible] = useState(false);
  const [chatId2, setChatId2] = useState("");
  const { waitingTime, getChatbotData, isChatbotActive } = useChatMessages(1, apiBaseHost);

  const getKeyFromUserName = async () => {
    try {
      const res = await Axios.get(`${apiBaseHost}/domainKey/viewKeyByUserName?userName=${userid}`);
      setChatId2(res.data.data);
    } catch {
      setChatId2("Nan");
    }
  };

  useEffect(() => {
    if (userid) {
      getKeyFromUserName();
    } else {
      setChatId2(bot_id);
    }
  }, [bot_id]);

  useEffect(() => {
    if (chatId2) {
      getChatbotData(chatId2);

      const urlParams = new URLSearchParams(window.location.search);
      const session = urlParams.get("session");
  
      if (session && chatId2) {
        // Update localStorage with the session ID
        localStorage.setItem(`chat-session-Id-${chatId2}`, session);
      }
    }
  }, [chatId2]);

  useEffect(() => {
    if (waitingTime > 0) {
      const timer = setTimeout(() => setIconsetVisible(true), waitingTime * 1000);
      return () => clearTimeout(timer);
    }
  }, [waitingTime]);

  const connectToSocket = (chatBotConnectid) => connectSocket(chatBotConnectid, () => { });
  const clearSocketState = () => {
    setChatClose(false);
    setSocketNewMessage(null);
  };

  const ChatBotComponent = chatbotComponents[template] || ChatBotIndex;

  return (
    <>
      {isChatbotActive && (
        <div className={styles.jinniBotBody}>
          <ChatBotComponent
            chatId2={chatId2}
            userid={userid}
            userImage={user_image}
            connectToSocket={connectToSocket}
            socketNewMessage={newSocketMessage}
            isChatClose={isChatClose}
            clearSocketState={clearSocketState}
            template={template}
            apiBaseUrl={apiBaseHost}
            isIconsetVisible={isIconsetVisible}
            setIconsetVisible={setIconsetVisible}
          />
        </div>
      )}
    </>
  );
};

export default App;
