export const debounce = (func, timeout = 800) => {
  let timer; // Variable to hold the timer reference

  // Return a new function that will execute the provided function (func) after a specified timeout
  return (...args) => {
    clearTimeout(timer); // Clear any existing timer

    // Set a new timer to execute the provided function with the provided arguments after the specified timeout
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
