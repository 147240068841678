import React, { useState } from 'react';
import { MenuItem, FormLabel, Popper, ClickAwayListener } from '@mui/material';
import styles from './InputStyles.module.css';

const DropdownSingle = ({ options, name, setCaptureFieldError, setCaptureFieldValue, postfix, prefix, template }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selected, setSelected] = useState(options[0]?.value);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const getHoverBorderColor = (template) => {
        const colors = {
            btc: "#3c6e95",
            talbies: "#B09981",
            charlessinclair: "#c7b354",
            denhan: "#b82142",
            daniels: "#090B0C",
            magicbrick: "#ffcc00",
            lundiniom: "#ffc800",
        };
        return colors[template] || "";
    };

    return (
        <div className={styles.inputContainer + " " + styles["input_" + template]} style={{ borderColor: getHoverBorderColor(template) }}>
            <FormLabel className={styles.inputLabel}>{name}</FormLabel>
            <div onClick={handleClick} className={styles.selectInput}>
                {selected}
            </div>
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="bottom-start"
                style={{ zIndex: 99999999, maxHeight: '200px', overflowY: 'auto', boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)" }}
                className={`${styles.main_popover} ${styles["popover_" + template]}`}

            >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <div className={styles.menuPaper}>
                        {options.map((option, index) => (
                            <MenuItem key={index} value={String(option?.label)}
                                onClick={() => {
                                    setAnchorEl(null)
                                    setSelected(option?.label)
                                    setCaptureFieldValue(
                                        (prefix ? prefix : "") +
                                        option?.value +
                                        (postfix ? " " + postfix + (parseFloat(option.value) > 1 ? "s" : "") : "")
                                    );

                                }}
                                style={{ fontSize: '13px', fontWeight:"500", fontFamily: "Inter" }}>
                                {option?.label}
                            </MenuItem>
                        ))}
                    </div>
                </ClickAwayListener>
            </Popper>
        </div >
    );
};

export default DropdownSingle;
