import React, { useState, useEffect } from 'react';
import { FormLabel, TextField, Typography } from '@mui/material';
import styles from './InputStyles.module.css';

const TextMinMax = ({ name, setCaptureFieldError, setCaptureFieldValue, prefix, postfix, template }) => {
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');
    const [error, setError] = useState('');

    // Validation logic
    useEffect(() => {
        if (min || max) {
            if (parseFloat(min) < 0 || parseFloat(max) < 0) {
                setError('Values must be positive numbers.');
                setCaptureFieldError(true);
            } else if (parseFloat(max) < parseFloat(min)) {
                setError('Max must be greater than or equal to Min.');
                setCaptureFieldError(true);
            } else {
                setError('');
                setCaptureFieldValue((prefix ? prefix : "") + min + (postfix ? " " + postfix : "") + ' - ' + (prefix ? prefix : "") + max + (postfix ? " " + postfix : ""))
                setCaptureFieldError(false);
            }
        }
    }, [min, max, setCaptureFieldError, setCaptureFieldValue]);

    const getHoverBorderColor = (template) => {
        console.log(template);

        const colors = {
            btc: "#3c6e95",
            talbies: "#B09981",
            charlessinclair: "#c7b354",
            denhan: "#b82142",
            daniels: "#090B0C",
            magicbrick: "#ffcc00",
            lundiniom: "#ffc800",
        };
        return colors[template] || "";
    };

    return (
        <div className={styles.inputContainer + " " + styles["input_" + template]} style={{ borderColor: getHoverBorderColor(template) }}>
            <FormLabel className={styles.inputLabel}>{name}</FormLabel>
            <div className={styles.dropdownContainer}>
                <div>
                    <Typography variant="caption" className={styles.dropdownLabel}>Min</Typography>
                    <TextField
                        value={min}
                        onChange={(e) => setMin(e.target.value)}
                        className={styles.textInput}
                        variant="outlined"
                        type="number"
                        error={!!error && min && parseFloat(min) < 0}
                    />
                </div>

                <div>
                    <Typography variant="caption" className={styles.dropdownLabel}>Max</Typography>
                    <TextField
                        value={max}
                        onChange={(e) => setMax(e.target.value)}
                        className={styles.textInput}
                        variant="outlined"
                        type="number"
                        error={!!error && max && (parseFloat(max) < parseFloat(min))}
                    />
                </div>
            </div>
            {error && (
                <Typography variant="caption" color="error" className={styles.errorText}>
                    {error}
                </Typography>
            )}
        </div>
    );
};

export default TextMinMax;
