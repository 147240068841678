import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IoIosArrowBack } from "react-icons/io";
import { Typography, Paper, Box, Button, Grid } from "@mui/material";
import moment from "moment";
import Axios from "../../utils/axios";
import Loader from "./Loader";

// Styled components for custom styling
const CalendarContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  width: 100%;
`;

const DateContainer = styled.div`
  box-shadow: 0px 0px 12.1px 0px #0000001c;
`;

const SlotContainer = styled.div`
  box-shadow: 0px 0px 12.1px 0px #0000001c;
`;

const DatePaper = styled(Paper)`
  padding: 8px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#cfe8fc" : "inherit")};
  box-shadow: none;
  border-bottom: 1px solid #efefef;
  border-radius: 0px;
  color: ${(props) =>
    ["magicbrick", "lundiniom"].includes(props.template) ? "#fff" : "#000"};
  &:hover {
    background-color: ${(props) => getHoverBackgroundColor(props.template)};
    color: ${(props) => getHoverTextColor(props.template)};
  }
`;

const HeaderTypography = styled(Typography)`
  padding: 0;
  height: 40px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    ["magicbrick", "lundiniom"].includes(props.template) ? "#fff" : "#000"};
  font-weight: 800;
`;

const BackIcon = styled(IoIosArrowBack)`
  width: 15px;
  font-size: 15px;
  height: 15px;
  margin: 8px;
  margin-bottom:0px;
  color: ${(props) =>
    ["magicbrick", "lundiniom"].includes(props.template) ? "#fff" : "#000"};
  cursor: pointer;
`;

const SlotButton = styled(Button)`
  padding: 8px;
  background-color: ${(props) => (props.slotAvailable ? "inherit" : "#eee")};
  width: 100%;
  display: ${(props) => (!props.slotAvailable ? "none" : "block")};
  color: ${(props) =>
    ["magicbrick", "lundiniom"].includes(props.template) ? "#fff" : "#000"};
  &:hover {
    background-color: ${(props) => getHoverBackgroundColor(props.template)};
    color: ${(props) => getHoverTextColor(props.template)};
  }
`;

// Utility functions for hover color based on template
const getHoverBackgroundColor = (template) => {
  const colors = {
    btc: "#202020",
    talbies: "#000",
    charlessinclair: "#c7b354",
    denhan: "#b82142",
    daniels: "#FED838",
    magicbrick: "#ffcc00",
    lundiniom: "#ffc800",
  };
  return colors[template] || "#ac6dfd";
};

const getHoverTextColor = (template) => {
  const colors = {
    btc: "#ffffff",
    talbies: "#fff",
    charlessinclair: "#fff",
    denhan: "#ffffff",
    magicbrick: "#000",
    daniels: "#000",
    lundiniom: "#000",
  };
  return colors[template] || "#fff";
};

// BotCal component
const BotCal = ({
  handleSendDailogApi,
  chatId,
  chatBotAdminData,
  stopSendloading,
  messages,
  template,
  apiBaseUrl,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slotsData, setSlotsData] = useState([]);
  const [dateLoad, setDateLoad] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setDateLoad(true);
      try {
        const params = {
          userid: chatBotAdminData.userId,
          isAgency: true,
          department: messages?.slice(-1)[0]?.category,
          tZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          sender: localStorage.getItem(`chat-session-Id-${chatId}`),
        };

        const response = await Axios.get(`${apiBaseUrl}/todo/get-slots-availability`, { params });
        setDateLoad(false);

        if (response.data.data) {
          const currentDateTime = moment();
          const filteredSlotsData = response.data.data.map((dateObj) => ({
            date: dateObj.date,
            slots: dateObj.slots.filter(
              (slot) =>
                moment(`${dateObj.date} ${slot.endTime}`, "MM/DD/YYYY hh:mm A").isAfter(currentDateTime) &&
                moment(`${dateObj.date} ${slot.startTime}`, "MM/DD/YYYY hh:mm A").isAfter(currentDateTime)
            ),
          }));
          setSlotsData(filterUnavailableDates(filteredSlotsData));
        }
      } catch (error) {
        setDateLoad(false);
      }
    };

    fetchData();
  }, [chatBotAdminData.userId, messages, chatId, apiBaseUrl]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };

  const handleSlotClick = (slot) => {
    if (slot.slotAvailable) {
      setSelectedSlot(slot);
    }
  };

  const filterUnavailableDates = (slots) =>
    slots.filter(({ slots }) => slots.some((slot) => slot.slotAvailable));

  return (
    <>
      {!dateLoad ? (
        <CalendarContainer className={template === "lundiniom" ? "lundiniom-calendar" : ""}>
          <DateContainer hidden={selectedDate}>
            <Typography variant="p" sx={{
              padding: 1, height: "25px", lineHeight: "30px", paddingBottom: "0", fontWeight: "700",
              marginTop: "4px", textAlign: "left", color: (template === "magicbrick" || template === "lundiniom") ? "#fff" : "#000"
            }}>
              Choose a date
            </Typography>
            <Box sx={{ overflowY: "auto", height: "250px" }}>
              {slotsData.map((dateObj) => (
                <DatePaper key={dateObj.date} onClick={() => handleDateClick(dateObj.date)} selected={selectedDate === dateObj.date} template={template}>
                  <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "14px" }}>
                    <div>{moment(dateObj.date).format("dddd")}</div>
                    <div>{moment(dateObj.date).format("MMMM D, YYYY")}</div>
                  </Typography>
                </DatePaper>
              ))}
            </Box>
          </DateContainer>

          <SlotContainer hidden={!selectedDate}>
            <HeaderTypography template={template}>
              <span onClick={() => { setSelectedDate(null); setSelectedSlot(null); }}>
                <BackIcon template={template} />
              </span>
              {moment(selectedDate).format("dddd, MMMM D")}
            </HeaderTypography>

            {selectedDate && (
              <Box sx={{ overflowY: "auto", height: "250px", overflowX: "hidden" }}>
                <Grid container spacing={0}>
                  {slotsData.find((dateObj) => dateObj.date === selectedDate)?.slots.map((slot, idx) => (
                    <Grid key={idx} xs={12} md={6} sx={{ borderRight: "1px solid #efefef", borderBottom: "1px solid #efefef" }}>
                      <SlotButton
                        onClick={() => {
                          if (!stopSendloading) {
                            handleSlotClick(slot);
                            handleSendDailogApi(moment(`${selectedDate} ${slot.startTime}`).format(), chatId, true);
                          }
                        }}
                        disabled={stopSendloading}
                        slotAvailable={slot.slotAvailable}
                        template={template}
                        hidden={!slot.slotAvailable}
                      >
                        {slot.startTime}
                      </SlotButton>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </SlotContainer>
        </CalendarContainer>
      ) : (
        <Loader size="medium" />
      )}
    </>
  );
};

export default BotCal;
