import { useState, useEffect, useCallback } from "react";
import socketIOClient from "socket.io-client";

const useSocket = (app_host) => {
  // State initialization
  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(false);
  const [isChatClose, setChatClose] = useState(false);
  const [newSocketMessage, setSocketNewMessage] = useState(null);

  // Function to connect to the socket server
  const connectSocket = useCallback((chatBotConnectid, onConnectCallback) => {
    // Creating a new socket instance with provided configuration
    const socketInstance = socketIOClient(app_host, {
      transports: ["websocket"], // Specify WebSocket as the transport mechanism
      reconnection: true, // Enable reconnection
      query: { id: chatBotConnectid, platform: "webbot" }, // Provide query parameters
    });

    // Event listeners for socket events
    socketInstance.on("connect", () => {
      setConnected(true); // Update connection status
      console.log("Socket Connected");
    });

    socketInstance.on("disconnect", () => {
      setConnected(false); // Update connection status
      console.log("Socket Disconnected");
    });

    // Event listener for incoming chat messages
    socketInstance.on("chatMessage", handleServerMessage);

    // Event listener for removing unassigned chats
    socketInstance.on("removeUnassignedChat", handleServerMessage);

    // Event listener for closing the chat
    socketInstance.on("closeChat", handleChatClose);

    // Set the socket instance in the state
    setSocket(socketInstance);
  }, []);

  // Function to handle incoming server messages
  const handleServerMessage = useCallback((newMessage) => {
    setSocketNewMessage(newMessage); // Set the new message in the state
  }, []);

  // Function to handle chat close event
  const handleChatClose = useCallback((newMessage) => {
    setChatClose(true); // Update chat close status
    // Prepare a message to prompt for rating
    const message = {
      userMessage: {
        message: "Please provide rating for this conversation.",
        type: "rating",
        options: ["Good", "Average", "Bad"],
        sender: "bot",
        field: "dailog_select",
        value: ""
      },
    };
    setSocketNewMessage(message); // Set the message in the state
  }, []);

  // Cleanup function to disconnect the socket when unmounting
  useEffect(() => {
    return () => {
      if (socket) { socket.disconnect(); } // Disconnect the socket if it exists
    };
  }, [socket]);

  // Return values and functions for external usage
  return {
    socket,
    connectSocket,
    connected,
    isChatClose,
    newSocketMessage,
    setChatClose,
    setSocketNewMessage,
  };
};

export default useSocket;
