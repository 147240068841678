import React, { useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import useAddress from "./hooks/useAddressFilter";
import styles from "./chatbot.module.css";
import { emoji, sendAction } from "../../constants/images";
import { FIELD_TYPE } from "../../constants/formFields";

const ChatbotMessageSend = ({ messages, chatId, isBotWorking, chatClose, onClose, handleEmojiSelect, captureFieldError, captureFieldValue
  , onChatMsgSubmit, handleRating, handleSendDailogApi, createChat, isAddress, stopSendloading, apiBaseUrl, createType, range, rangeData, }) => {

  // State and variables initialization
  const [msg, setMsg] = useState(""); // State for message input

  const { onChangeAddress, placesData, showList, setShowList, handleSaveAddresswithpostCode, fullAddress, setFullAddress } = useAddress(apiBaseUrl); // Using custom address hook
  const isFormAlreadySubmitted = JSON.parse(localStorage.getItem(chatId)); // Checking if form is already submitted
  const textareaRef = useRef(); // Ref for message input
  const [anchorEl, setAnchorEl] = React.useState(null); // State for popover anchor


  // Function to handle click event on emoji icon
  const handleClick = (event) => {
    if (!range && (!FIELD_TYPE?.includes(messages?.[messages.length - 1]?.customFieldTitle?.fieldType))) {
      setAnchorEl(event.currentTarget);
    }
  };

  // Function to handle close event for popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Variables for popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Function to handle key down event
  function handleKeyDown(e) {
    if (
      e.key === "Enter" && // Check if Enter key is pressed
      (isAddress ? (isAddress && fullAddress) : true) &&
      isBotWorking && // Check if bot is working
      (!captureFieldError) &&
      msg && // Check if message is not empty
      messages?.[messages.length - 1]?.field !== "dailog_select" && // Check if last message is not a dialog select
      !stopSendloading && // Check if sending is not stopped
      (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation") // Check if not one of these types
    ) {

      if (chatClose) { // Check if chat is closing
        handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId); // Handle rating
      } else {

        if (!createChat) {
          handleSendDailogApi(msg, chatId, false, fullAddress, setFullAddress); // Send message using API
          setMsg(""); // Clear message input
        } else {
          onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg); // Submit chat message
        }
      }
    }
  }

  useEffect(() => {
    if (range) {
      setMsg(rangeData + " " + range)
      textareaRef.current.focus();
    }
  }, [rangeData, range]);

  useEffect(() => {
    if (captureFieldValue) {
      setMsg(captureFieldValue)
    }
  }, [captureFieldValue])

  return (
    <>{isBotWorking ?
      <div className={styles.all_chatpart_footer + " d-flex"}>
        <img src={emoji} alt="" onClick={handleClick} /> {/* Emoji icon */}
        <Popover
          id={id} open={open} anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{ zIndex: "9999999999" }}
        >
          {/* Emoji picker */}
          <Picker
            data={data}
            onEmojiSelect={(emoji) => handleEmojiSelect(emoji, textareaRef, setMsg, handleClose)}
            theme="light" emojiSize={18} previewPosition="none"
          />
        </Popover>

        {isAddress ? ( // If address is needed
          <Autocomplete
            disablePortal open={showList && msg?.length > 1}
            id="select-on-focus"
            selectOnFocus
            disableClearable
            inputValue={msg}
            filterOptions={(options, state) => options}
            onChange={(event, newValue) => {
              handleSaveAddresswithpostCode(newValue, setMsg)
              setShowList(false);
            }}
            options={placesData} sx={{ width: 300, color: "#464749", p: 0, m: 0 }}
            getOptionLabel={(option) => option?.description}
            getOptionSelected={(option, value) => option?.description === value?.description}
            renderOption={(props, option) => (
              <Box {...props} sx={{ padding: "0", margin: "0" }}>
                <span className={styles.placeList} style={{ padding: "0", margin: "0", width: "100%", borderBottom: "none", }} >
                  {option.description}
                </span>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                value={msg}
                {...params}
                className={styles.address_input}
                placeholder="Search address ..."
                autoFocus={true}
                onChange={(e) => {
                  if (!range) {
                    setMsg(e.target.value);
                    if (isAddress && e.target.value.length > 1) {
                      onChangeAddress(e, messages?.slice(-1)[0]?.category);
                    } else { setShowList(false); }
                  }
                }}
                onKeyPress={(e) => { handleKeyDown(e); }}
              />
            )}
          />
        ) : ( // If normal text input is needed
          <input
            type="text"
            ref={textareaRef} name=""
            id=""
            placeholder={messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "multiple-checkbox" ?
              "Please select options above." :
              messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "dropdown-min-max" ?
                "Please select min & max in option above." :
                messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "text-min-max" ?
                  "Please enter min & max in above fields." :
                  (messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "single-radio" || messages?.[messages.length - 1]?.customFieldTitle?.fieldType === "dropdown-single") ?
                    "Please select an option above." : "Enter your message.."
            }
            value={msg}
            disabled={range && !msg}
            onChange={(e) => {
              if (!range && (!FIELD_TYPE?.includes(messages?.[messages.length - 1]?.customFieldTitle?.fieldType))) {
                setMsg(e.target.value);
              }
            }}
            onKeyPress={(e) => { handleKeyDown(e); }}
          />
        )}
        {/* Send button */}

        <div
          onClick={(e) => {
            if (
              isBotWorking && // Check if bot is working
              (isAddress ? (isAddress && fullAddress) : true) &&
              (!captureFieldError) &&
              msg && // Check if message is not empty
              !stopSendloading && // Check if sending is not stopped
              messages?.[messages.length - 1]?.field !== "dailog_select" && // Check if last message is not a dialog select
              (createType !== "arrange-callback" && createType !== "property-viewing" && createType !== "schedule-valuation") // Check if not one of these types
            ) {
              if (chatClose) { // Check if chat is closing
                handleRating(msg, setMsg, onClose, isFormAlreadySubmitted, chatId); // Handle rating
              } else {
                if (!createChat) {
                  handleSendDailogApi(msg, chatId, false, fullAddress, setFullAddress); // Send message using API
                  setMsg(""); // Clear message input
                } else {
                  onChatMsgSubmit(e, msg, chatId, isFormAlreadySubmitted, setMsg); // Submit chat message
                }
              }
            }
          }}
        >
          <img className={styles.send_icon} src={sendAction} alt="" /> {/* Send icon */}
        </div>
      </div>
      : <div className={styles.conversation_close}>
        This chat session has ended
      </div>}
    </>
  );
};

export default ChatbotMessageSend; // Exporting component