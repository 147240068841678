import axios from "axios";

// Create an Axios instance with a baseURL (you can specify the base URL here)
const Axios = axios.create({ baseURL: "" });

// Adding authorization token in request header using request interceptor
Axios.interceptors.request.use((config) => {
  // You can modify the request config here before sending it
  return config;
});

// Adding error handling for responses using response interceptor
Axios.interceptors.response.use(
  // If the response is successful, return the response data
  (response) => response,
  // If there's an error, reject the promise with an error message
  (error) =>
    Promise.reject(
      // Get error message from response data, or default to a generic message if not available
      error.response.data.error || error.response.data.message || "Something went wrong"
    )
);

// Export the Axios instance with configured interceptors
export default Axios;
