import React from "react";
import Chatbot from "./Chatbot"; // Importing the Chatbot component
import styles from "./chatbot.module.css"; // Importing styles
import { RxCross2 } from "react-icons/rx"; // Importing cross icon
import { chatIconlundiniom } from "../../constants/images"; // Importing image


const ChatBotLundiniomIndex = ({ chatId2, isIconsetVisible, setIconsetVisible, userImage, connectToSocket, socketNewMessage, isChatClose, clearSocketState, template, apiBaseUrl }) => {

  // Function to toggle the visibility of the chat icon
  const toggleIconset = () => {
    // Toggle the visibility of the chat icon
    setIconsetVisible((prev) => !prev);
    // Clear socket state
    clearSocketState();
  };

  return (
    <div style={{ zIndex: "999998", position: "fixed"  }}  >
      <div className={styles.full_chatbot}>
        {/* Render the Chatbot component if the chat icon is visible */}
        <span className="online_span"></span>
        {isIconsetVisible && (
          <Chatbot
            chatId2={chatId2}
            userImage={userImage}
            onClose={toggleIconset}
            connectToSocket={connectToSocket}
            socketNewMessage={socketNewMessage}
            isChatClose={isChatClose}
            template={template}
            apiBaseUrl={apiBaseUrl}
          />
        )}

        <div className={styles.iconset} >
          {/* Render the close icon if the chat icon is visible */}
          {isIconsetVisible && (<div onClick={toggleIconset} className={styles.crossico}> <RxCross2 />  </div>)}
          {/* Render the chat icon if it is not visible */}
          {!isIconsetVisible && (
            <div onClick={toggleIconset} className={styles.online_wrapper}>
              <div className={styles.icon_wrap}>
                <div className={styles.showicon}>
                  {/* Chat icon */}
                  <img src={chatIconlundiniom} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBotLundiniomIndex;
