module.exports.isValidRating = (msg) => {
  // Convert the message to lowercase before comparing
  const lowerCaseMsg = msg.toLowerCase();
  // Check if the message is one of the valid ratings
  return (lowerCaseMsg === "good" || lowerCaseMsg === "bad" || lowerCaseMsg === "average");
};

// Wrap setTimeout in a Promise
module.exports.delayedExecution = () =>
  new Promise((resolve) => {
    const timeoutId = setTimeout(() => {
      resolve();
      clearTimeout(timeoutId); // Clear the timeout after execution
    }, 1000);
  });
