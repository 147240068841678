import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { FaEllipsisV } from "react-icons/fa";

import styles from "./chatbot.module.css";
import ChatbotMessageSend from "./ChatbotMessageSend"; // Importing ChatbotMessageSend component
import ChatbotMessages from "./ChatbotMessages"; // Importing ChatbotMessages component
import Loader from "../custom/Loader"; // Importing Loader component
import useChatMessages from "../Chatbot/hooks/useChatMessages"; // Importing custom hook
import { chatImgdenhan, chatCancel, logo } from "../../constants/images"; // Importing images

export default function Chatbot({ chatId2, onClose, connectToSocket, socketNewMessage, isChatClose, userImage, template, apiBaseUrl }) {

  // Destructuring values from the custom hook useChatMessages
  const { currentChatId, hasChatCreated, createType, createChat, messages, setMessages, loading, chatLoading,
    setchatLoading, isBotWorking, setIsBotWorking, chatClose, setChatClose, handleCloseChat, ValidateKey, handleRating, handleEmojiSelect,
    onChatMsgSubmit, handleSendDailogApi, chatBotAdminData, isAddress, stopSendloading,range, maxRange } = useChatMessages(localStorage?.getItem(`chat-session-Id-${chatId2}`) || uuidv4(), apiBaseUrl);

  // State for Popover
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rangeData, setRangeData] = useState(0);
  const [captureFieldError, setCaptureFieldError] = useState("");
  const [captureFieldValue, setCaptureFieldValue] = useState("");
  // Function to handle click on Popover
  const handleClick = (event) => { setAnchorEl(event.currentTarget); };
  // Function to handle close of Popover
  const handleClose = () => { setAnchorEl(null); };
  // Boolean value to check if Popover is open
  const open = Boolean(anchorEl);
  // Unique ID for Popover
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // Validate chat ID
    if (chatId2) { ValidateKey(chatId2); }
  }, [chatId2]);

  useEffect(() => {
    // Connect to socket when chat ID is available
    if (currentChatId) {
      console.log("Connecting on API Response", currentChatId);
      connectToSocket(currentChatId);
    } 
    else if (chatId2) {
      console.log("Connect the socket on page load");
      const chatDataStr = localStorage.getItem(chatId2);
     
      if (chatDataStr) {
        const chatBotConnectid = JSON.parse(chatDataStr)._id;
        connectToSocket(chatBotConnectid);
      }
    } else {
      console.log("Error count not connect the socket.");
    }
  }, [hasChatCreated, currentChatId]);

  useEffect(() => {
    // Update messages on new message
    if (socketNewMessage) {
      if (isChatClose || socketNewMessage?.userMessage?.close) { setChatClose((prev) => isChatClose); }
      setMessages((prevMessages) => [...prevMessages, socketNewMessage?.userMessage || socketNewMessage?.userMessageNew,]);
      if (socketNewMessage?.userMessage?.close) {
        let userMessage = {
          message: "Please provide rating for this conversation.",
          type: "rating",
          options: ["Good", "Average", "Bad"],
          sender: "bot",
          field: "dailog_select",
          value: ""
        }
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setChatClose((prev) => true);
      }
    }
  }, [socketNewMessage]);

  return (
    <div className={styles.all_chatpart_design}>
      <div className={styles.all_chatpart_header}>
        {loading ? <div className={styles.with_avtar}>
          <div className={styles.icon_wrapper}>
            <img src={chatBotAdminData?.chatBotData?.chatbotImage || chatImgdenhan} alt="" />
          </div>
        </div> : <div className={styles.with_avtar}>
          <div className={styles.icon_wrapper}>
            <img src={chatBotAdminData?.chatBotData?.chatbotImage || chatImgdenhan} alt="" />
            <span className={styles.online_span}></span>
          </div>
          {chatBotAdminData?.chatBotData?.chatbotName || ""}
        </div>}
        <div className={styles.more_Option}>
          <div onClick={handleClick} className={styles.donw_arrow}>
            <FaEllipsisV />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ zIndex: 999999 }}>
            <Typography
              sx={{ px: 2, py: 1, cursor: "pointer" }}
              onClick={() => handleCloseChat(chatId2, onClose, handleClose)}>
              Close Chat
            </Typography>
          </Popover>
          <div onClick={onClose} className={styles.donw_arrow}>
            <img src={chatCancel} />
          </div>
        </div>
      </div>

      {loading ? (
        // If loading is true, display a loader
        <div className={styles.all_chatpart_body}>
          <Loader size="medium" />
        </div>
      ) : (
        // If not loading, render the ChatbotMessages component
        <ChatbotMessages
          // Pass necessary props to ChatbotMessages component
          chatId={chatId2}
          setIsBotWorking={setIsBotWorking}
          messages={messages}
          chatLoading={chatLoading}
          setchatLoading={setchatLoading}
          setMessages={setMessages}
          setChatClose={setChatClose}
          handleSendDailogApi={handleSendDailogApi}
          createChat={createChat}
          onChatMsgSubmit={onChatMsgSubmit}
          createType={createType}
          chatBotAdminData={chatBotAdminData}
          stopSendloading={stopSendloading}
          onClose={onClose}
          chatClose={chatClose}
          handleRating={handleRating}
          template={template}
          apiBaseUrl={apiBaseUrl}
          range={range}
          maxRange={maxRange}
          setRangeData={setRangeData}
          rangeData={rangeData}
          setCaptureFieldError={setCaptureFieldError}
          setCaptureFieldValue={setCaptureFieldValue}
        />
      )}
      {/* Render the ChatbotMessageSend component */}
      <ChatbotMessageSend
        // Pass necessary props to ChatbotMessageSend component
        onClose={onClose}
        messages={messages}
        isBotWorking={isBotWorking}
        chatClose={chatClose}
        chatId={chatId2}
        handleRating={handleRating}
        handleEmojiSelect={handleEmojiSelect}
        onChatMsgSubmit={onChatMsgSubmit}
        handleSendDailogApi={handleSendDailogApi}
        createChat={createChat}
        isAddress={isAddress}
        stopSendloading={stopSendloading}
        apiBaseUrl={apiBaseUrl}
        createType={createType}
        captureFieldError={captureFieldError}
        captureFieldValue={captureFieldValue}
        setCaptureFieldValue={setCaptureFieldValue}
        range={range}
        maxRange={maxRange}
        setRangeData={setRangeData}
        rangeData={rangeData}
      />
      {/* Display "Powered By" section */}
      <div className={styles.powered_box}>
        Powered By <img src={logo} alt="" /> <span>Propertyjinni</span>
      </div>
    </div>
  );
}
